<template>
  <div class="passwordResetConfirm" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Réinitialisation de votre mot de passe</h1>
        <div class="form_div">
          <div class="form_content">
            <div v-if="passwordReset">
              <div class="text-center">
                <div>
                  <b-img :src="require('@/assets/images/success.svg')" style="max-width: 10%;'"
                         fluid alt="Mot de passe modiifé"  />
                </div>
                <h2 class="mt-3">
                  Votre mot de passe a bien été modifié
                </h2>
              </div>
              <div class="row mt-4">
                <div class="col-12 text-center">
                  <b-button variant="outline-info" pill block @click="$router.push('/login')" style="white-space: normal;">
                    Connectez-vous
                  </b-button>
                </div>
              </div>
            </div>
            <div v-else>
              <b-form v-on:submit.prevent="confirmResetPassword()" class="mt-4">
                <b-form-group id="password-input" label="Votre mot de passe" label-for="password-input">
                  <b-form-input
                    v-model="password" type="password"
                    maxlength="200"
                    id="password-input"
                    @input="$v.password.$touch()"
                    :state="$v.password.$dirty ? !$v.password.$error : null">
                  </b-form-input>
                  <div class="form-error" v-if="!$v.password.password_validation">Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial.</div>
                </b-form-group>
                <b-form-group id="password-confirmation-input" label="Confirmation de votre mot de passe" label-for="password-confirmation-input">
                  <b-form-input
                    v-model="passwordConfirmation" type="password"
                    maxlength="200"
                    id="password-confirmation-input"
                    @input="$v.passwordConfirmation.$touch()"
                    :state="$v.passwordConfirmation.$dirty ? !$v.passwordConfirmation.$error : null">
                  </b-form-input>
                  <small class="text-danger" v-if="!$v.passwordConfirmation.sameAsPassword">Veuillez rentrer deux mots de passe identiques</small>
                </b-form-group>
                <small v-if="errorPasswordMessage" class="text-danger"> {{errorPasswordMessage}} </small>
                <b-button
                  type="submit"
                  pill block :variant="confirmResetPasswordButtonStyle" class="mt-3"
                  :disabled="$v.password.$invalid || $v.passwordConfirmation.$invalid || confirmPasswordResetingInProcess">
                    <b-spinner small label="Confirm Reset Password Spinner" v-if="confirmPasswordResetingInProcess"></b-spinner> {{ confirmResetPasswordButtonText }}
                </b-button>
              </b-form>
            </div>

            <!-- ERROR MODAL -->
            <b-modal ref="GlobalAPIErrorModal" id="GlobalAPIErrorModal" hide-footer title="Erreur globale">
              <div class="text-center">
                <p>Une erreur s'est produite, veuillez réactualiser la page ou vous reconnecter</p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('GlobalAPIErrorModal')">Fermer</b-button>
              </div>
            </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import formTitleComponent from '@/components/formTitleComponent'

import { password_validation } from '@/validators/validators'

import { AuthAPIService } from '@/api/APIAuth'

const apiAuth = new AuthAPIService()


export default {
  name: 'PasswordResetConfirm',
  components: {
    formTitleComponent
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      password: '',
      passwordConfirmation: '',
      confirmResetPasswordToken: '',

      confirmResetPasswordButtonText: 'Réinitialiser',
      confirmResetPasswordButtonStyle: 'outline-info',
      confirmPasswordResetingInProcess: false,

      errorPasswordMessage: '',
      passwordReset: false
    }
  },
  mounted: function() {
    this.confirmResetPasswordToken = this.$route.query.token
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
  },
  validations: {
    password: {
      required,
      maxLength: maxLength(200),
      minLength: minLength(8),
      password_validation
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs('password'),
      maxLength: maxLength(200),
      minLength: minLength(2)
    }
  },

  methods: {
    confirmResetPassword () {
      this.confirmResetPasswordButtonText = 'Réinitialisation en cours'
      this.confirmResetPasswordButtonStyle = 'secondary'
      this.confirmPasswordResetingInProcess = true
      apiAuth.confirmResetPassword(this.confirmResetPasswordToken, this.password )
      .then(() => {
          this.passwordReset = true
        }, (error) => {
          if (error.response.status == 400) {
            this.errorPasswordMessage = error.response.data.email[0]
          }
          else {
            this.showGlobalAPIErrorModal()
          }
        })
      .finally(() => {
        this.confirmResetPasswordButtonText = 'Réinitialiser'
        this.confirmResetPasswordButtonStyle = 'outline-info'
        this.confirmPasswordResetingInProcess = false
      })
    },

    showGlobalAPIErrorModal () {
      this.$refs.GlobalAPIErrorModal.show()
    },
    goLogin () {
      this.$router.push('/login')
    },
  }
}
</script>
